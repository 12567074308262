import React from "react";
import { Link } from "gatsby-plugin-react-intl";

import ReactLogo from "../static/lchtgst_logo_only_black_paths.svg";

const Imprint = () => {
    return (
        <div className="imprint h-100">
            <div className="row h-100 no-gutters">
                <div className="col-lg-6 col-md-8 col-sm-10 imprintLeftText">
                    <div className="imprintLogoContainer">
                        <div className="contactTitleLogo">
                            <ReactLogo className="aboutLogo"/>
                        </div>
                        <div className="contactTitleHeader">
                            <h1>imprint</h1>
                        </div>
                    </div>
                    <div className="imprintLeftContent">
                        <div className="contactSection">
                            <p className="imprintLine">
                            lichtgestalten<br/>
                            Kaiser & Schatz GbR<br/>
                            Rotebühlstr. 59a<br/>
                            70178 Stuttgart<br/>
                            <br/>
                            Studio München<br/>
                            Auenstr. 90<br/>
                            80469 München
                            </p>
                            <br/>
                            <Link className="imprintLine" to={"/agb"}>AGB</Link>
                            <br/>
                            <br/>
                            <p className="imprintLine">Inhaltlich verantwortlich für den redaktionellen Teil nach § 55 II RStV: Philipp Kaiser, Dominik Schatz</p>
                            <br/>
                            <br/>
                            <br/>
                            <p className="imprintLine">Designed by
                                <a className="contactMailTo" href="https://bareis-nicolaus.com" target="_blank" rel="noopener noreferrer"> Bareis+Nicolaus</a><br></br>
                                Developed by Philipp Schatz, lichtgestalten<br></br>
                                &#169; {(new Date().getFullYear())} lichtgestalten
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-18 col-md-16 col-sm-14 imprintText">
                    <h1>Disclaimer</h1>
                    <h2 className="imprintH2">Haftung für Inhalte</h2>
                    <p className="imprintP">Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hier von unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                    <h2 className="imprintH2">Haftung für Links</h2>
                    <p className="imprintP">Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                    <h2 className="imprintH2">Urheberrecht</h2>
                    <p className="imprintP">Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
                    <h2 className="imprintH2">Kontaktformular</h2>
                    <p className="imprintP">Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
                    <br className="imprintBr"></br>
                    <hr></hr>
                    <h1>Datenschutzerklärung</h1>
                    <h2 className="imprintH2">Allgemeines</h2>
                    <p className="imprintP">Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">I. Informationen über uns als Verantwortliche</p>
                    <p className="imprintP">II. Rechte der Nutzer und Betroffenen</p>
                    <p className="imprintP">III. Informationen zur Datenverarbeitung</p>
                    <h2 className="imprintH2">I. Informationen über uns als Verantwortliche</h2>
                    <p className="imprintP">Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Kaiser & Schatz GbR</p>
                    <p className="imprintP">Rotebühlstr. 59a</p>
                    <p className="imprintP">70178 Stuttgart</p>
                    <p className="imprintP">Deutschland</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Telefon: 004917631400649</p>
                    <p className="imprintP">E-Mail: info@lichtgestalten.li</p>
                    <h2 className="imprintH2">II. Rechte der Nutzer und Betroffenen</h2>
                    <p className="imprintP">Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</p>
                    <ul>
                        <li>auf Bestätigung, ob die betreffenden Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
                        <li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>
                        <li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</li>
                        <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
                        <li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
                    </ul>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</p>
                    <h2 className="imprintH2">III. Informationen zur Datenverarbeitung</h2>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>
                    <h3 className="imprintH3">Serverdaten</h3>
                    <p className="imprintP">Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.</p>
                    <h3 className="imprintH3">Cookies</h3>
                    <p className="imprintP">a) Sitzungs-Cookies/Session-Cookies</p>
                    <p className="imprintP">Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">b) Drittanbieter-Cookies</p>
                    <p className="imprintP">Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">c) Beseitigungsmöglichkeit</p>
                    <p className="imprintP">Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.</p>
                    <h3 className="imprintH3">Kontaktanfragen / Kontaktmöglichkeit</h3>
                    <p className="imprintP">Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich – ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.</p>
                    {/*
                    <h3 className="imprintH3">„Facebook“-Social-Plug-in</h3>
                    <p className="imprintP">In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Facebook ein. Bei Facebook handelt es sich um einen Internetservice der facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. In der EU wird dieser Service wiederum von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, betrieben, nachfolgend beide nur „Facebook“ genannt.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
                    <p className="imprintP"><a className="imprintLink" href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a></p>
                    <p className="imprintP">garantiert Facebook, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Weitergehende Informationen über die möglichen Plug-ins sowie über deren jeweilige Funktionen hält Facebook unter</p>
                    <p className="imprintP"><a className="imprintLink" href="https://developers.facebook.com/docs/plugins/" target="_blank">https://developers.facebook.com/docs/plugins/</a></p>
                    <p className="imprintP">für Sie bereit.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Sofern das Plug-in auf einer der von Ihnen besuchten Seiten unseres Internetauftritts hinterlegt ist, lädt Ihr Internet-Browser eine Darstellung des Plug-ins von den Servern von Facebook in den USA herunter. Aus technischen Gründen ist es dabei notwendig, dass Facebook Ihre IP-Adresse verarbeitet. Daneben werden aber auch Datum und Uhrzeit des Besuchs unserer Internetseiten erfasst.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Sollten Sie bei Facebook eingeloggt sein, während Sie eine unserer mit dem Plug-in versehenen Internetseite besuchen, werden die durch das Plug-in gesammelten Informationen Ihres konkreten Besuchs von Facebook erkannt. Die so gesammelten Informationen weist Facebook womöglich Ihrem dortigen persönlichen Nutzerkonto zu. Sofern Sie also bspw. den sog. „Gefällt mir“-Button von Facebook benutzen, werden diese Informationen in Ihrem Facebook-Nutzerkonto gespeichert und ggf. über die Plattform von Facebook veröffentlicht. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts bei Facebook ausloggen oder durch den Einsatz eines Add-ons für Ihren Internetbrowser verhindern, dass das Laden des Facebook-Plug-in blockiert wird.</p>
                    <br className="imprintBr"></br>
                    <p className="imprintP">Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Facebook in den unter</p>
                    <p className="imprintP"><a className="imprintLink" href="https://www.facebook.com/policy.php" target="_blank">https://www.facebook.com/policy.php</a></p>
                    <p className="imprintP">abrufbaren Datenschutzhinweisen bereit.</p>
                    */}
                    <h3 className="imprintH3">Vimeo</h3>
                    <p className="imprintP">In unserem Internetauftritt setzen wir Vimeo ein. Hierbei handelt es sich um ein Videoportal der Vimeo Inc., 555 West 18th Street New York, New York 10011, USA, nachfolgend nur „Vimeo“ genannt. Datenschutzerklärung: https://vimeo.com/privacy. Wir weisen darauf hin, dass Vimeo Google Analytics einsetzen kann und verweisen hierzu auf die Datenschutzerklärung (https://www.google.com/policies/privacy) sowie Opt-Out-Möglichkeiten für Google-Analytics (https://tools.google.com/dlpage/gaoptout?hl=de) oder die Einstellungen von Google für die Datennutzung zu Marketingzwecken (https://adssettings.google.com/.).</p>
                    <br className="imprintBr"></br>
                </div>
            </div>
        </div>
    );
};

export default Imprint;