import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Imprint from "../components/imprint"

import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl";

const SecondPage = ({intl}) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: "titleImprint" })} lang={intl.locale} />
    <Imprint></Imprint>
  </Layout>
)

export default injectIntl(SecondPage)
